// 默认导出
export default {
  // 数据存储对象
  data: () => {
    return {
      // 查询表单字段
      form: {
        userName: null, // 用户名称
        zsxm: null, // 真实姓名
        userRole: null // 角色
      },
      // 查询表单
      formSearchList: [
        {
          type: "input",
          label: "参会者类型：",
          value: "userRole",
          placeholder: "请选择"
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          type: "input",
          label: "参会者角色：",
          value: "userRole",
          placeholder: "请选择"
          // queryParams: {
          //   sqlId: "8f703a9f-9c12-41ab-84fc-a92f0ac1524a",
          //   dictionaryId: "e3fbd1d0-38c4-4b24-8715-6eea3cbed95a"
          // }
        },
        {
          type: "input",
          label: "参会者名称：",
          value: "chzmc",
          placeholder: "请输入"
        }
      ],
      // 表头按钮组
      btnList: [
        {
          name: "日志导出",
          type: "primary",
          icon: "document-add"
        }
      ],
      // 表格基础数据
      tableColumns: [
        {
          label: "参会者名称",
          prop: "chzmc"
        },
        {
          label: "参会者类型",
          prop: "chzlx"
        },
        {
          label: "参会者角色",
          prop: "chzjs"
        },
        {
          label: "网络地址",
          prop: "wldz"
        },
        {
          label: "环境检测情况",
          prop: "hjjcqk"
        },
        {
          label: "网卡地址",
          prop: "wkdz"
        },
        {
          label: "硬盘序列号",
          prop: "ypxlh"
        },
        {
          label: "状态",
          prop: "zt"
        }
      ],
      // 表格数据
      tableData: [
        {
          chzmc: "肖帅",
          chzlx: "远程分散开评标工具录屏主页",
          chzjs: "2020-10-15 17:56:49",
          wldz: "点击会议结束",
          hjjcqk: "hjjcqk",
          wkdz: "hjjcqk",
          ypxlh: "wkdz",
          zt: "点击会议结束"
        },
        {
          chzmc: "肖帅",
          chzlx: "远程分散开评标工具录屏主页",
          chzjs: "2020-10-15 17:56:49",
          wldz: "点击会议结束",
          hjjcqk: "hjjcqk",
          wkdz: "hjjcqk",
          ypxlh: "wkdz",
          zt: "点击会议结束"
        },
        {
          chzmc: "肖帅",
          chzlx: "远程分散开评标工具录屏主页",
          chzjs: "2020-10-15 17:56:49",
          wldz: "点击会议结束",
          hjjcqk: "hjjcqk",
          wkdz: "hjjcqk",
          ypxlh: "wkdz",
          zt: "点击会议结束"
        },
        {
          chzmc: "肖帅",
          chzlx: "远程分散开评标工具录屏主页",
          chzjs: "2020-10-15 17:56:49",
          wldz: "点击会议结束",
          hjjcqk: "hjjcqk",
          wkdz: "hjjcqk",
          ypxlh: "wkdz",
          zt: "点击会议结束"
        },
        {
          chzmc: "肖帅",
          chzlx: "远程分散开评标工具录屏主页",
          chzjs: "2020-10-15 17:56:49",
          wldz: "点击会议结束",
          hjjcqk: "hjjcqk",
          wkdz: "hjjcqk",
          ypxlh: "wkdz",
          zt: "点击会议结束"
        }
      ],
      // 分页
      reqPageData: {
        page: 1, // 页码
        limit: 10, // 每页条数
        total: 10 // 总条数
      },
      multipleSelection: []
    };
  },
  // 模板编译完成
  mounted() {
    // 获取一览表格数据
    this.fun_GetList();
  },

  // 自定义方法
  methods: {
    /**
     * 获取一览表格数据
     * @author 滕威
     */
    fun_GetList() {},
    /**
     * 点击修改按钮
     * @author 滕威
     */
    fun_Edit() {
      // 判断是否勾选
      // if (this.multipleSelection && this.multipleSelection.length > 0) {
      //   // 勾选超过1条
      //   if (this.multipleSelection.length > 1) {
      //     // 提示，并清除选中数据
      //     this.$alert("只能选择一条数据！", "warning").then(() => {
      //       // 清除选中数据
      //       this.$refs.list.$refs.multipleTable.clearSelection();
      //     });
      //   } else {
      //     // 打开抽屉
      //     this.$refs.userManageDrawer.drawerStatus = true;
      //   }
      // } else {
      //   // 未勾选，则提示
      //   this.$alert("请选择一条数据！", "warning");
      // }
    },
    /**
     * 点击删除按钮
     * @author 滕威
     */
    fun_Del() {
      // 判断是否勾选
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        this.$message.success("删除成功!");
      } else {
        // 未勾选，则提示
        this.$alert("请选择一条数据！", "warning");
      }
    },
    /**
     * 查询
     * @author 滕威
     */
    fun_Search() {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取一览表格数据
      this.fun_GetList();
    },
    /**
     * 表头按钮事件
     * @params e 按钮name
     * @author 滕威
     */
    fun_click(e) {
      switch (e) {
        case "添加":
          // 打开抽屉
          // this.$refs.userManageDrawer.drawerStatus = true;
          break;
        case "修改":
          // 调用修改方法
          this.fun_Edit();
          break;
        default:
          // 调用删除方法
          this.fun_Del();
      }
    },
    /**
     * 勾选事件
     * @params e 选中的数据
     * @author 滕威
     */
    fun_HandleSelectionChange(e) {
      // 将勾选的value值赋给multipleSelection数组
      this.multipleSelection = e;
    }
  }
};
